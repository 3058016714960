











import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    InsHeader: () => import('@/components/business/header/InsHeader.vue'),
    InsSlideMenu: () => import('@/components/business/header/InsSlideMenu.vue'),
    InsMenuLayout: () => import('@/components/business/header/InsMenuLayout.vue'),
    InsCopyright: () => import('@/components/business/footer/InsCopyright.vue')
  }
})
export default class RNPLayout extends Vue {
  get isMobile () {
    return this.$store.state.isMobile;
  }
}
